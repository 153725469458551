'use client';

import React, { PropsWithChildren, useMemo, useState } from 'react';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import classNames from 'classnames';

import { RecapAsset } from '@/api/trading/types';
import { Container } from '@/blocks/components/Container';
import tabsStyles from '@/blocks/components/Controls/Tabs/styles.module.scss';
import { useSignup } from '@/blocks/components/PlatformLink';
import { CellHeading, Table, TableRow } from '@/blocks/components/Table';
import { WithClassName } from '@/blocks/components/types';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import context from '@/styles/contexts.module.scss';

import { DeprecatedTabsOverflowContainer } from '../../components/DeprecatedTabs';
import styles from './styles.module.scss';

export function MarketsSectionTabs({ children }: PropsWithChildren) {
  const tabs: any[] = React.Children.toArray(children).filter((c: any) => c?.props?.label);
  const tabsContents: any[] = React.Children.toArray(children).filter((c: any) => !c?.props?.label);

  const [value, setValue] = useState(tabs?.[0]?.props?.value);

  if (!tabs.length || !tabsContents.length) {
    return null;
  }

  return (
    <div className={classNames(styles.tabs)}>
      <TabContext value={value}>
        <DeprecatedTabsOverflowContainer>
          {tabs.length > 1 ? (
            <TabList
              variant={'scrollable'}
              scrollButtons={false}
              className={classNames(tabsStyles.tabs)}
              onChange={(event, newValue) => setValue(newValue)}
            >
              {tabs}
            </TabList>
          ) : null}
        </DeprecatedTabsOverflowContainer>
        <Container>{tabsContents}</Container>
      </TabContext>
    </div>
  );
}

export function MarketsTable({
  assetsToDisplay,
  columns: cols,
  assets,
  className,
  columnsVisibilityModel,
}: PropsWithChildren<
  WithClassName & {
    assets: RecapAsset[];
    assetsToDisplay: string[];
    columns: any[];
    columnsVisibilityModel: Record<string, string[]>;
  }
>) {
  const bp = useBreakpoint();
  const { openSignupWithAsset } = useSignup();

  const columns = useMemo(
    () =>
      bp && Array.isArray(columnsVisibilityModel[bp])
        ? columnsVisibilityModel[bp]
            .map((colName) => cols.find((col) => col.name === colName))
            .filter(Boolean)
        : cols,
    [bp, cols, columnsVisibilityModel],
  );

  return (
    <Table className={classNames(styles.table, context.dark, className)}>
      {columns
        .filter((col) => Boolean(col.title))
        .map((column, index, arr) => (
          <CellHeading
            key={index}
            data-column-name={column.name}
            {...(index === arr.length - 1 && arr.length < columns.length && { colSpan: 2 })}
          >
            {column.title}
          </CellHeading>
        ))}

      {assetsToDisplay.map((asset, key) => {
        const marketsAsset = assets.find((a) => a.name === asset);
        if (!marketsAsset) {
          if (typeof window !== 'undefined') {
            console.error(`[${MarketsTable.name}] Not found asset ${asset} in api`);
          }
          return;
        }
        return (
          <TableRow key={key} onClick={() => openSignupWithAsset(marketsAsset)}>
            {columns.map((col) => col.renderCell(marketsAsset))}
          </TableRow>
        );
      })}
    </Table>
  );
}
