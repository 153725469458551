import { ReactNode } from 'react';

import classNames from 'classnames';

import { LinkRewardCenter } from '@/blocks/components/PlatformLink';
import { RewardCenterSection } from '@/blocks/components/PlatformLink/RewardCenterSection';
import buttons from '@/styles/button.module.scss';
import context from '@/styles/contexts.module.scss';
import typography from '@/styles/typography.module.scss';

import styles from './PromoCard.module.scss';

export type PromoCardProps = {
  title: ReactNode;
  description: ReactNode;
  reward: ReactNode;
  cta: ReactNode;
  enCta: ReactNode;
  footnote: ReactNode;
  rewardCenterSection: RewardCenterSection;
};

type Card = PromoCardProps & {
  params?: { [key: string]: any };
  isActive: boolean;
};

export const PromoCard = (props: Card) => {
  return (
    <div className={classNames(context.light, styles.promoCard, { [styles.active]: props.isActive })}>
      <div className={classNames(styles.promoCardContent)}>
        <h4 className={classNames(typography.h4, styles.promoCardTitle)}>{props.title}</h4>
        <p className={classNames(typography.bodyM, styles.promoCardSubtitle)}>{props.description}</p>
        <div className={classNames(styles.promoCardCtaContainer)}>
          <LinkRewardCenter
            rewardCenterSection={props.rewardCenterSection}
            className={classNames(buttons.btn, buttons.btnM, buttons.btnPrimary)}
            // params={props.params}
            data-event-label={props.enCta}
          >
            {props.cta}
          </LinkRewardCenter>
          <p className={classNames(typography.bodyM, styles.promoCardFootnote)}>{props.footnote}</p>
        </div>
      </div>
      <div className={classNames(styles.promoCardBonus, typography.h2)}>{props.reward}</div>
    </div>
  );
};
