import(/* webpackMode: "eager", webpackExports: ["Rating"] */ "__barrel_optimize__?names=Rating!=!/opt/atlassian/pipelines/agent/build/node_modules/@mui/material/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/opt/atlassian/pipelines/agent/build/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/index/AwardSection/img/award-1.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/index/AwardSection/img/award-2.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/index/AwardSection/img/award-3.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/index/GlobalCommunitySection/img/users.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/index/HeroTools/CtaBlock/apple.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/index/HeroTools/CtaBlock/google.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/index/HeroTools/images/award.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/index/HeroTools/images/hero-africa.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/index/HeroTools/images/hero-tr.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/index/HeroTools/images/hero.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/index/HeroTools/images/user-1.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/index/HeroTools/images/user-2.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/index/HeroTools/images/user-3.png");
;
import(/* webpackMode: "eager", webpackExports: ["Tables"] */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/index/IndexMarketsSection/Tables/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/index/IndexProductCards/img/product-buycrypto.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/index/IndexProductCards/img/product-cdfsforex.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/index/IndexProductCards/img/product-copytrading.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/index/IndexProductCards/img/product-cryptofutures.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/index/OldHero/icons/awards/advfn.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/index/OldHero/icons/awards/coin-gecko.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/index/OldHero/icons/awards/forex-awards.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/index/OldHero/icons/products/buy-crypto-light.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/index/OldHero/icons/products/buy-crypto.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/index/OldHero/icons/products/cfd-light.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/index/OldHero/icons/products/cfd.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/index/OldHero/icons/products/copy-trading-light.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/index/OldHero/icons/products/copy-trading.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/index/OldHero/icons/products/crypto-futures-light.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/index/OldHero/icons/products/crypto-futures.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/index/PromotionsCards/images/contests.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/index/PromotionsCards/images/gift-box.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/index/PromotionsCards/images/rocket.png");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/blocks/components/HeadingAccentFragment/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/blocks/components/layout/Section/Section.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/blocks/components/layout/VStack/VStack.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/blocks/components/layout/CenterHeading/CenterHeading.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/blocks/components/Container/styles.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/components/QrCode/APK/apk-qr-primexbt-ch.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/components/QrCode/APK/apk-qr-primexbt-co-za.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/components/QrCode/APK/apk-qr-primexbt-com.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/components/QrCode/APK/apk-qr-primexbt-markets-pro.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/components/QrCode/APK/apk-qr-primexbt-one.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/components/Rating/assets/empty.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/components/Rating/assets/star.svg");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/blocks/components/SectionsGroup/styles.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Typing"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/components/Typing/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FormatPercent"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/formatters/format-percent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Mobile/images/mobile-trader.png");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/styles/background.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/styles/contexts.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/styles/rtl.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/styles/button.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/styles/typography.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Cta/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Footer/components/FooterSocialLinks/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Footer/components/FooterContactsAndSocialsSection/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Footer/components/FooterLayout/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Footer/components/FooterLeftActionGroup/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Footer/components/FooterTrustpilot/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Footer/components/FooterLegalSection/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Footer/components/FooterLinksBar/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Footer/components/FooterNav/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Footer/variants/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/blocks/components/BonusCard/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Header/Versions/WaitlistHeader/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Platform/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/blocks/components/Separator/Separator.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/blocks/components/TopIconCard/TopIconCard.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/blocks/components/Disclaimer/Disclaimer.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Products/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Promos/PromosSectionCta/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Testimonials/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/UspTabs/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/index/AwardSection/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/index/GlobalCommunitySection/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/blocks/components/IconsStack/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/index/HeroTools/CtaBlock/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/index/HeroTools/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Markets/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/blocks/components/AssetIcon/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/index/IndexProductCards/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/blocks/components/DividedRowInfo/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/styles/link.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/index/PromotionsCards/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/blocks/components/QrCode/QrCode.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/blocks/components/StepsList/StepsList.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Mobile/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/index/StepsSection/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/blocks/components/Cards/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/blocks/components/Rating/rating.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Hero/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/index/OldHero/indexHero.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["AnimatedText"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/animations/animated-text.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppearInView"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/animations/AppearInView.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HideOnQueryParam"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/components/HideOnQueryParam/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NBSP"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/components/I18nSubstitutions/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/blocks/components/Icons/IconCurvedArrow/IconCurvedArrow.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Logo"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/components/Logo/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LinkSignup","LinkRewardCenter","platformRewardsURL","platformContestsURL","platformRewardsWelcomeBonusURL"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/components/PlatformLink/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/components/QrCode/qr.svg");
;
import(/* webpackMode: "eager", webpackExports: ["APKButtonShort","AppStoreButton","GooglePlayButton","APKButton"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/components/StoreButton/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FormatAmount","FormatPrice"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/formatters/format-price.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Cta/images/afro-cta.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Cta/images/arab-cta.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Cta/images/asia-cta.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Cta/images/brz-cta.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Cta/images/eur-cta.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Cta/images/mex-cta.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Footer/assets/logo_cryptovalley.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Footer/components/FooterBottomBigLogo/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContactAndSupportButton"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Footer/components/FooterContactsAndSocialsSection/ContactAndSupportButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FooterMobileAppsBlockQr"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Footer/components/FooterLeftActionGroup/FooterMobileApps/FooterMobileAppsBlockQr/index..tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FooterMobileAppIOSButton","FooterMobileAppAndroidButton"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Footer/components/FooterLeftActionGroup/FooterMobileApps/FooterMobileAppsButtons.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Footer/components/FooterSocialLinks/icons/discord.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Footer/components/FooterSocialLinks/icons/fb-icon.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Footer/components/FooterSocialLinks/icons/instagram-icon.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Footer/components/FooterSocialLinks/icons/linked_in.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Footer/components/FooterSocialLinks/icons/reddit-icon.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Footer/components/FooterSocialLinks/icons/telegram.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Footer/components/FooterSocialLinks/icons/tiktok-icon.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Footer/components/FooterSocialLinks/icons/twitter-icon.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Footer/components/FooterSocialLinks/icons/yt-icon.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Footer/components/FooterTrustpilot/img/logo.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Footer/components/FooterTrustpilot/img/stars.png");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderActionsWithoutPromotions","HeaderCommonActions"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Header/Components/Actions/ActionsContainer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderGiftBanner"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Header/Components/Actions/GiftBanner/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DownloadAppBanner"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Header/Components/DownloadAppBanner/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderInner"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Header/Components/HeaderInner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MenuCtaBlockMobileStoreButton"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Header/Components/Mobmenu/CtaBlock/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ConvertersWidget"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Header/Components/Widgets/Converters/ConvertersWidget.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderPartners"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Header/Versions/HeaderPartners/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HelpCenter"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/HelpCenter/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/PartnersEvents/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Products/images/buy-crypto.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Products/images/cfds.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Products/images/copy-trading.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Products/images/crypto-futures.png");
;
import(/* webpackMode: "eager", webpackExports: ["PromoSlider"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Promos/PromoSlider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Testimonials/components/CardsContent/CardsContent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Testimonials/components/img/greenStars.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Testimonials/components/img/stars.svg");
;
import(/* webpackMode: "eager", webpackExports: ["ReviewsSlider"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Testimonials/components/ReviewsSlider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Testimonials/img/appStore.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Testimonials/img/google.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Testimonials/img/yellowStars.svg");
;
import(/* webpackMode: "eager", webpackExports: ["UspTabs"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/UspTabs/index.tsx");
