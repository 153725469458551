'use client';

import { ReactNode } from 'react';

import { ImagesSlider } from '@/blocks/components/ImagesSlider';
import { Section } from '@/blocks/components/layout';

import eventImage1 from './images/Photo-1.png';
import eventImage2 from './images/Photo-2.png';
import eventImage3 from './images/Photo-3.png';
import eventImage4 from './images/Photo-4.png';
import eventImage5 from './images/Photo-5.png';
import eventImage6 from './images/Photo-6.png';
import eventImage7 from './images/Photo-7.png';
import eventImage8 from './images/Photo-8.png';
import eventImage9 from './images/Photo-9.png';
import eventImage10 from './images/Photo-10.png';

const images = [
  eventImage1,
  eventImage2,
  eventImage3,
  eventImage4,
  eventImage5,
  eventImage6,
  eventImage7,
  eventImage8,
  eventImage9,
  eventImage10,
];

type Props = {
  title: ReactNode;
  subheading: ReactNode;
};

export const PartnersEvents = ({ title, subheading }: Props) => {
  return (
    <Section>
      <ImagesSlider slides={images} title={title} subheading={subheading} />;
    </Section>
  );
};

export default PartnersEvents;
