'use client';

import React, { ReactNode, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import { useInView } from 'framer-motion';
import Image from 'next/image';
import 'swiper/css';
import { Autoplay, Navigation } from 'swiper/modules';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';

import { AnimatedText } from '@/blocks/animations/animated-text';
import { Container } from '@/blocks/components/Container';
import { SlideNext, SlidePrev } from '@/blocks/components/Slider/Arrows';
import { VStack } from '@/blocks/components/layout';
import typography from '@/styles/typography.module.scss';

import styles from './ImagesSlider.module.scss';

type Props = {
  slides: any[];
  title: ReactNode;
  subheading: ReactNode;
};

export const ImagesSlider = ({ slides, title, subheading }: Props) => {
  const containerRef = useRef(null);
  const inView = useInView(containerRef);
  const [swiper, setSwiper] = useState<SwiperClass>();
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (!swiper) {
      return;
    }

    if (inView) {
      swiper?.autoplay?.start();
    } else {
      swiper?.autoplay?.stop();
    }
  }, [inView, swiper]);

  return (
    <div
      className={styles.section}
      style={{ opacity: isLoaded ? 1 : 0, transition: 'opacity 0.4s' }}
      ref={containerRef}
    >
      <VStack>
        <Container>
          <div className={styles.header}>
            <div className={styles.headerContent}>
              <AnimatedText el={'h2'} className={typography.h2}>
                {title}
              </AnimatedText>
              <p className={classNames(typography.bodyM, typography.headerSubhead)}>{subheading}</p>
            </div>
            <div className={classNames(styles.headerArrows)}>
              <SlidePrev className={styles.prev} />
              <SlideNext className={styles.next} />
            </div>
          </div>
        </Container>
        <div className={styles.containerLeft}>
          <div className={styles.container}>
            <Swiper
              modules={[Navigation, Autoplay]}
              onSwiper={setSwiper}
              loop={true}
              grabCursor={true}
              slidesPerView={1.15}
              spaceBetween={8}
              speed={400}
              navigation={{
                prevEl: `.${styles.prev}`,
                nextEl: `.${styles.next}`,
              }}
              autoplay={{
                delay: 2500,
                pauseOnMouseEnter: true,
              }}
              breakpoints={{
                768: {
                  slidesPerView: 'auto',
                  spaceBetween: -8,
                },
              }}
            >
              {slides?.map((item, i) => (
                <SwiperSlide key={i} className={styles.slide}>
                  {({ isActive }) => (
                    <div className={classNames(styles.content, { [styles.active]: isActive })}>
                      <Image src={item.src} alt={''} width={653} height={436} draggable={false} />
                    </div>
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        <div className={styles.bottomArrows}>
          <SlidePrev className={styles.prev} />
          <SlideNext className={styles.next} />
        </div>
      </VStack>
    </div>
  );
};
