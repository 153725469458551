'use client';

import React, { useEffect, useState } from 'react';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import { Autoplay, EffectCoverflow, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { SlideNext, SlidePrev } from '@/blocks/components/Slider/Arrows';
import { PromoCard, PromoCardProps } from '@/blocks/sections/Promos/PromoSlider/PromoCard';

import styles from './PromoSlider.module.scss';

export type PromosSliderProps = {
  promos: PromoCardProps[];
};

export const PromoSlider = ({ promos }: PromosSliderProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <div className={styles.container} style={{ opacity: isLoaded ? 1 : 0, transition: 'opacity 0.3s ease' }}>
      <Swiper
        effect={'coverflow'}
        modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
        autoplay={{
          delay: 2500,
          pauseOnMouseEnter: true,
        }}
        navigation={{
          prevEl: `.${styles.prevSlide}`,
          nextEl: `.${styles.nextSlide}`,
        }}
        loop={true}
        initialSlide={1}
        slidesPerView={1.1}
        centeredSlides={true}
        spaceBetween={8}
        speed={400}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 0,
          modifier: 1,
          slideShadows: false,
        }}
        breakpoints={{
          768: {
            slidesPerView: 'auto',
            centeredSlides: true,
            spaceBetween: 0,
            coverflowEffect: {
              rotate: 0,
              stretch: 0,
              depth: 0,
              modifier: 1,
              scale: 0.91,
              slideShadows: false,
            },
          },
        }}
      >
        {promos?.length > 0 &&
          promos.map((slide, i) => (
            <SwiperSlide key={i} className={styles.slide}>
              {({ isActive }) => <PromoCard {...slide} isActive={isActive} />}
            </SwiperSlide>
          ))}
      </Swiper>
      <div className={styles.navigation}>
        <div className={styles.navigationContainer}>
          <SlidePrev variant={'chevron'} className={styles.prevSlide} />
          <SlideNext variant={'chevron'} className={styles.nextSlide} />
        </div>
      </div>
    </div>
  );
};
